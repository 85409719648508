import React, { useState } from "react"
import SButton from "../../UIElements/Button/button"
import './ContactComponent.css'
import Swal from 'sweetalert2'
import Validator from "../../Util/validator"
import GoogleMapReact from 'google-map-react'
import Marker from '../../UIElements/Marker/Marker'
// import './map.css'
const ContactComponent = (config) => {
    const {action} = config
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mail, setEmail] = useState("")
    const [motivo, setMotivo] = useState("consulta")
    const [detalle, setDetalle] = useState("")
    const {reference} = config
    const [validName, setValidName] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [validPhone, setValidPhone] = useState(false)
    const [validDetail, setValidDetail] = useState(true)
    const [validOption, setValidOption] = useState(false)
    const [canSendMail, setCanSendMail] = useState(true)
    const [canBeButtonEnabled, setCanBeButtonEnabled] = useState(false)
    const onConfirm = async () => {
        const result = {
            name, phone, mail, motivo, detalle
        }
        if (!validName) {
            Swal.fire({title: "El nombre es inválido! por favor ingrese un nombre válido", icon:"error", confirmButtonText:"Ok!"})
        }
        if (!validEmail) {
            Swal.fire({title: "El email es inválido! por favor ingrese un email válido", icon:"error", confirmButtonText:"Ok!"})
        }
        if (!validPhone) {
            Swal.fire({title: "El teléfono es inválido! por favor ingrese un numero válido", icon:"error", confirmButtonText:"Ok!"})
        }
        if (!validOption) {
            Swal.fire({title: "El motivo es inválido! por favor ingrese un motivo.", icon:"error", confirmButtonText:"Ok!"})
        }
        if (!validDetail) {
            Swal.fire({title: "Su consulta es muy largo! por favor resumala", icon:"error", confirmButtonText:"Ok!"})
        }
        setCanSendMail(false)
        evaluateButton()
        try {
            await action({
                phone,
                email: mail,
                contacttype: motivo,
                name,
                message: detalle
            })
            setCanSendMail(false)
            evaluateButton()
            Swal.fire({title: "Hemos recibido su consulta, nos pondremos en contacto lo antes possible!", icon:"success", confirmButtonText:"Ok!"})
        } catch(error) {
            setCanSendMail(true)
            evaluateButton()
            Swal.fire({title: "No se pudo recibir su consulta, intentelo sino por Email o por Whatsapp!", icon:"error", confirmButtonText:"Ok!"})
        }
    }
    const onNameUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validateName(value)
            setValidName(true)
        } catch (error) {
            console.log('error validando nombre', error)
            setValidName(false)
        }
        setName(value)
        evaluateButton()
    }
    const onEmailUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validateEmail(value)
            setValidEmail(true)
        } catch (error) {
            console.log('error validando nombre', error)
            setValidEmail(false)
        }
        setEmail(value)
        evaluateButton()
    }
    const onPhoneUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validatePhone(value)
            setValidPhone(true)
        } catch (error) {
            console.log('error validando nombre', error)
            setValidPhone(false)
        }
        setPhone(value)
        evaluateButton()
    }
    const onDetailUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validateDetail(value)
            setValidDetail(true)
        } catch (error) {
            console.log('error validando detalle', error)
            setValidDetail(false)
        }
        setDetalle(value)
        evaluateButton()
    }
    const onOptionUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validateOptions(value)
            setValidOption(true)
        } catch (error) {
            console.log('error validando opción', error)
            setValidOption(false)
        }
        setMotivo(value)
        evaluateButton()
    }
    const evaluateButton = () => {
        setCanBeButtonEnabled(validPhone && canSendMail && validDetail && validOption && validEmail && validName)
    }
    // const location = {
    //     address: '1600 Amphitheatre Parkway, Mountain View, california.',
    //     lat: 37.42216,
    //     lng: -122.08427,
    //   }
    //   const Map = ({ location, zoomLevel }) => (
    //     <div className="map">
    //       <h2 className="map-h2">Come Visit Us At Our Campus</h2>
      
    //       <div className="google-map">
    //         <GoogleMapReact
    //           bootstrapURLKeys={{ key: '' }}
    //           defaultCenter={location}
    //           defaultZoom={zoomLevel}
    //         >
    //           {/* <LocationPin
    //             lat={location.lat}
    //             lng={location.lng}
    //             text={location.address}
    //           /> */}
    //         </GoogleMapReact>
    //       </div>
    //     </div>
    //   )
      const defaultProps = {
        center: {
          lat: -34.6011999020408,
          lng: -58.3786812959184
        },
        zoom: 17
      };
      const AnyReactComponent = ({ text }) => <div>{text}</div>;

    return <div className="contactcomponent" id="contact-form" ref={reference}>
        <div className="row">
            <div className="col-sm-6">
                <div className="contactformbox row pl-3 pr-3">
                    <img className="contact-bottom-left-frame" src="/frame-contact-bl.png" />
                    <img className="contact-top-right-frame" src="/frame-contact-tr.png" />
                    <p className="contactTitle col-sm-12">CONTACTO</p>
                    <p className="contactSubtitle col-sm-12">Tenés una idea?</p>
                    <p className="contactDescription col-sm-12">Escribinos y contanos de tu proyecto</p>
                    <input type="text" className="contactinputsmallform mt-5 col-sm-5" maxlength="36" id="" aria-describedby="emailHelp" placeholder="Nombre" onChange={(value) => onNameUpdate(value.target.value)} value={name} />
                    <div className="col-sm-1"></div>
                    <input type="email" className="contactinputsmallform mt-5 col-sm-6" maxlength="25" id="" aria-describedby="emailHelp" placeholder="Email" onChange={(value) => onEmailUpdate(value.target.value)} value={mail} />
                    <input type="tel" className="contactinputform mt-5 col-sm-12" maxlength="12" id="" aria-describedby="emailHelp" placeholder="Telefono" onChange={(value) => onPhoneUpdate(value.target.value)} value={phone} />
                    <select className="contactinputform mt-5 pr-3 col-sm-12" id="" aria-describedby="emailHelp" placeholder="Tipo de consulta" onChange={(value) => onOptionUpdate(value.target.value)} value={motivo}>
                        <option value="consulta" >-</option>
                        <option value="consulta-grabacion" >Grabación</option>
                        <option value="consulta-mezcla" >Mezcla</option>
                        <option value="consulta-master" >Mastering</option>
                        <option value="consulta-produccion" >Producción</option>
                    </select>
                    <textarea className="contactinputdetailform mt-5 pt-4 col-sm-12" maxlength="300" id="" aria-describedby="emailHelp" placeholder="Mensaje" onChange={(value) => onDetailUpdate(value.target.value)} value={detalle} />
                    <div className="buttonContainer">
                        <SButton text="Enviar" onClick={onConfirm} disabled={!canBeButtonEnabled} />
                    </div>
                    
                    
                </div>
                
            </div>
            {/* <div className="col-sm-6"> */}
            {/* <div style={{height: 440,width: 440}}> */}
            {/* <div className="mapabox"> */}
       {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDZaE6O5kJzRX30pNY8zLCtdklbgV7Upsg" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker
          lat={-34.6011999020410}
          lng={-58.3786812959184}
          text="My Marker"
        />
      </GoogleMapReact> */}
    {/* </div> */}
            {/* </div> */}
        </div>
        
    </div>
}

export default ContactComponent