import React, { useState } from "react";
import SButton from "../../UIElements/Button/button";
import './NewsLetterComponent.css'
import Validator from "../../Util/validator";
import Swal from "sweetalert2";
const NewsLetterComponent = (config) => {
    const {action} = config
    const [email, setEmail] = useState("")
    const [isEmailValid, setEmailValid] = useState(false)
    const [buttonEnabled, setButton] = useState(false)
    const onEmailUpdate = async (value) => {
        console.log(value)
        try {
            await Validator.validateEmail(value)
            setEmailValid(true)
        } catch (error) {
            console.log('error validando nombre', error)
            setEmailValid(false)
        }
        setEmail(value)
    }
    const onConfirm = async () => { 
        if (!isEmailValid) {
            Swal.fire({title: "El email es inválido! por favor ingrese un email válido", icon:"error", confirmButtonText:"Ok!"})
        }
        setButton(false)
        try {
            await action(email)
            setButton(true)
            Swal.fire({title: "Se ha registrado el email con éxito, le enviaremos novedades y promociones, sus datos están seguros con nosotros.", icon:"success", confirmButtonText:"Ok!"})
        } catch (error) {
            Swal.fire({title: "No se pudo registrar su email, o éste ya fue previamente registrado.", icon:"error", confirmButtonText:"Ok!"})
            setButton(true)
        }
        
    }
    // return <div className="news-container">
        
        return <div className="newlettercomponent">
        <img className="news-top-left-frame" src="/frame-news-tl.png" width="380" />
        <img className="news-bottom-right-frame" src="/frame-news-br.png" width="380" />
        <div className="news-container">
        <p className="newstitle mt-5 pb-5">NEWSLETTER</p>
        <p className="newsmessage mt-5 pb-3">¡Tenemos los mejores tips para vos!</p>
        <p className="newsdescription mt-4 pb-5">Querés acceder a contenido de manera exclusiva? Dejanos tu email y recibí los recursos gratis!</p>
        {/* <div class="newletterform"> */}
            {/* <label for="exampleInputEmail1">Email address</label> */}
            <input type="email" className="newsinputform mt-5" id="" maxlength="25" aria-describedby="emailHelp" placeholder="Email" onChange={(value) => onEmailUpdate(value.target.value)} value={email} />
            <div className="pb-5 mb-5 mt-2">
                <small id="emailHelp" class="text-muted">Prometemos no enviarte spam ni compartir tu email!</small>
            </div>
        {/* </div> */}
        <SButton text="suscribirme" onClick={onConfirm} disabled={buttonEnabled} />
    </div>
 </div>
}

export default NewsLetterComponent