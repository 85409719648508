import React from "react";
import SButton from "../../UIElements/Button/button";
import CardIconText from "../../UIElements/CardIconText/CardIconText";
import "./ServiciosComponent.css"
const data = {
    title: "Nuestros Servicios",
    servicios: [
        {
            image: "./grabacion.png",
            title: "Grabación",
            description: "Grabamos con equipos valvulares y a transistores, con micrófonos y preamplificadores clásicos que se encuentran en grandes estudios. \n Especializados en baterías. \nGrabacion a distancia: sesionistas , baterías, bajos, guitarras, voces, sintetizadores."
        },
        {
            image: "./mezcla.png",
            title: "Mezcla",
            description: "Buscamos que tu música tenga un sonido profesional, con un sonido propio. Primero realizamos una entrevista para analizar qué sonido estás buscando. Nos basamos en pre mezclas o tus preferencias musicales para potenciar aspectos técnicos y/o musicales. \nEntregamos el tema sin masterizar, con headroom, consultar por stems."
        },
        {
            image: "./mastering.png",
            title: "Mastering",
            description: "Siendo el mastering el último proceso creativo, buscamos previamente escuchar la mezcla realizada y de ser necesario pedimos algunas correcciones o stems para un resultado óptimo. \nMasterizamos tu música para las diferentes plataformas digitales(Youtube, Spotify, Tidal, iTunes, entre otros). \nRealizamos mastering en estéreo o por stems previamente definidos y opcional de revisión de mezcla."
        },
    ]
}
const ServiciosComponent = (config) => {
    const {action} = config
    const servicios = (data) => {
        const rows = data.map(servicio => {
            return <CardIconText image={servicio.image} title={servicio.title} description ={servicio.description} />
        })
        return (
            <div className="row">
                {rows}
            </div>
        )
    }
    return (
        <div className="cuerposervicios">
            
            <div className="container pb-5">
                <p className="serviciostitle">{data.title}</p>    
                {servicios(data.servicios)}
            </div>
            <SButton text="Pedir cotización" onClick={() => action()} />
        </div>
    )
}

export default ServiciosComponent