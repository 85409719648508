import React, { useState } from "react";
import './DropdownItem.css'
const DropdownItem = (data) => {
    const {title, items, show} = data
    const [showContent, updateShowContent] = useState(show)
    const createDropDown = (items) => {
        if (!showContent) {
            return 
        }
        return <div><hr className="orangeSeparator" /><ul>{items.map(item => <li className="dropdownItem">{item.text}</li>)}</ul></div>
    } 
    return <div className="container dropdownBox" onClick={() => updateShowContent(!showContent)}>
        <p className="dropdownTitle">{title}</p>
        {/* {showContent ? } */}
        <img src={ showContent ? "/desplegable-up.png" : "/desplegable-down.png"} className="dropDownArrow" />
        {createDropDown(items)}
    </div>
}

export default DropdownItem