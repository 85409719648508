import React from "react";
import './LogosComponent.css'

const files = [
    {
        uri: '/logos/acustica-audio.png',
        height: 32,
        width: 80
    },
    {
        uri: '/logos/back-rooster.png',
        height: 32,
        width: 80
    },
    {
        uri: '/logos/liquidsonics.png',
        height: 32,
        width: 70
    },
    {
        uri: '/logos/pro-tools.png',
        height: 32,
        width: 32
    },
    {
        uri: '/logos/waves.png',
        height: 32,
        width: 60
    },
    {
        uri: '/logos/stam.png',
        height: 32,
        width: 80
    },
    {
        uri: '/logos/weiss.png',
        height: 32,
        width: 70
    },
    {
        uri: '/logos/softube-vector-logo.png',
        height: 32,
        width: 70
    },
    {
        uri: '/logos/melodyne.png',
        height: 32,
        width: 60
    },
    {
        uri: '/logos/plug-in-alliance.png',
        height: 28,
        width: 80
    },
    {
        uri: '/logos/valhalla-dsp.gif',
        height: 20,
        width: 120
    },
    {
        uri: '/logos/apogee.png',
        height: 32,
        width: 40
    },
    {
        uri: '/logos/ua.svg',
        height: 32,
        width: 60
    },
    {
        uri: '/logos/shure.svg',
        height: 32,
        width: 60
    },
    {
        uri: '/logos/electro-voice.webp',
        height: 18,
        width: 100
    },
    {
        uri: '/logos/sennheiser.webp',
        height: 16,
        width: 120
    },
    {
        uri: '/logos/amphion.svg',
        height: 20,
        width: 120
    },
    {
        uri: '/logos/solidstatelogic.webp',
        height: 22,
        width: 100
    },
]
const Logos = () => {
    const logos = () => {
        return files.map(logo => {
            // return logo.uri
            return <div className="logoimage">
                <img src={logo.uri} height={logo.height * 1.2} width={logo.width *1.2} />
            </div>
            
        })
    }
    return <div className="content logosBackground">
        <p className="logostitle">TRABAJAMOS CON</p>
        <div className="logosbox">
            {logos()}
        </div>
    </div>
}

export default Logos