import React, { useRef } from 'react';
import CarrouselHeader from '../Component/CarrouselHeader/CarrouselHeader';
import AboutComponent from '../Component/About/About';
import ServiciosComponent from '../Component/ServiciosComponent/ServiciosComponent';
import PackComponent from '../Component/PackComponent/PackComponent';
import NewsLetterComponent from '../Component/NewsLetter/NewsLetterComponent';
import ContactComponent from '../Component/Contact/ContactComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Component/Footer/FooterComponent'
import ServerAPI from '../API/ContactRequests';
import ReviewComponent from '../Component/Reviews/ReviewsComponent';
const carouselData = {
    slides: [
        {
            id: 234,
            backgroundURI: '/home-horizontal2.png',
            backgroundURIALT: '/homeVertical.jpg',
            title: "Expertos en sonido".toUpperCase(),
            text: "811 ESTUDIO DE GRABACIÓN",
            button: "hacer una reserva"
        }
    ]
}

const HomeScreen = (config) => {
    const {top, contact} = config
    const location = useLocation()
    const navigate = useNavigate()
    console.log(location.pathname)
    const navigateToHome = () => {
        navigate('/')
    }
    const navigateToContact = () => {
        // console.log('execute')
        // navigate('#contact-form')
        console.log('navigate to contact')
        contact.current.scrollIntoView()
    }
    const navigateToStudio = () => {
        console.log('navigate to studio')
        navigate('/studio')
        top.current.scrollIntoView()
    }
    const navbarItems = [
        {
            name: 'inicio',
            action: navigateToHome,
            isSelected: true
        },
        {
            name: 'Studio',
            action: navigateToStudio,
            isSelected: false
        }
    ]
    const sendContact = async (body) => {
        console.log(body)
        await ServerAPI.Contact(body)
    }
    const suscribe = async (email) => {
        await ServerAPI.Suscribe(email)
    }
    return (
        <div>
            <CarrouselHeader data={carouselData} action={navigateToContact} />
            <AboutComponent action={navigateToStudio} />
            <ServiciosComponent action={navigateToContact} />
            <PackComponent action={navigateToContact} />
            <NewsLetterComponent action={suscribe} />
            <ReviewComponent />
            <ContactComponent reference={contact} action={sendContact} />
            
        </div>
    )
}

export default HomeScreen