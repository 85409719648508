import React from "react";
import './FooterComponent.css'
const Footer = (props) => {
    const {items} = props
    let buildItems = () => {
        return items.map(item => {
            return <div className="col" key={item.title}><a href={item.url} className="navbar-link"><p className="footer-redes-text">{item.title.toUpperCase()}</p></a></div>
            // return <div className='col'><a href={item.url} className='navbar-link'><p>{item.title.toUpperCase()}</p></a></div>
        })
    }
    return <div className="footer-body">
        <div>
        <a className="navbar-brand" href="/">
                    <img className="mt-3 ml-5" src="/logo2.png" width="70" height="58" alt="" />
                </a>
        </div>
        <div className="center-body row">
            {buildItems()}
            {/* <p className="col footer-redes-text">INICIO</p>
            <p className="col footer-redes-text">ESTUDIO</p> */}
        </div>
        <div className="footer-redes">
        
            <div className="footer-redes-social">
            <p className="footer-redes-text footer-redes-social"></p>
            <a className="footer-red" href="https://www.facebook.com/estudiodegrabacion811" target="_blank"><img src="/facebook-w2.png" width="32px" height="32px" /></a>
            <a className="footer-red" href="https://www.instagram.com/estudiodegrabacion811/" target="_blank"><img src="/instagram-w2.png" width="22px" height="22px" /></a>
            </div>
            
                {/* <a href="#"><img src="/tiktok.png" width="23px" height="23px" /></a> */}
                {/* <p><a className="footer-redes-text-link" href="mailto:info@estudio811.com"> info@estudio811.com</a></p> */}
        </div>
        {/* <p className="footer-redes-text">Footer</p> */}
    </div>
}

export default Footer