import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import NavigationBar from './Component/Navbar/Navbar';
import HomeScreen from './Screens/HomeScreen';
import StudioScreen from './Screens/StudioScreen';
import WhatsAppButton from './UIElements/Whatsapp/whatsapp';
import { useRef } from 'react';
import Footer from './Component/Footer/FooterComponent';
// import 'sweetalert2/src/sweetalert2.scss'

const router = (topref, contactRef) => createBrowserRouter(
  [
    {
      path: '/',
      element: <HomeScreen top={topref} contact={contactRef} />
    },
    {
      path: '/studio',
      element: <StudioScreen top={topref} contact={contactRef} />
    }
    
  ]
)
const items = [
  {
    url: '/',
    title: 'Inicio'
  },
  {
    url: '/studio',
    title: 'Estudio'
  }
]
function App() {
  const topRef = useRef(null)
  const contactRef = useRef(null)
  return (
    <div className="App" ref={topRef}>
      <NavigationBar items={items} />
      <RouterProvider router={router(topRef, contactRef)} />
      <WhatsAppButton /> 
      <Footer items={items} />
    </div>
  );
}

export default App;
