import Axios from 'axios'


const postAction = async (url, body) => {
    let response = await Axios.post(url, body)
    return response.data
}
const API = {
    postAction
}
export default API
