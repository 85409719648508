import React from "react";
import CarouselExample from "../../CarouselComponentBootstrap";
import CarrouselHeader from "../CarrouselHeader/CarrouselHeader";
import './GalleryComponent.css'
import Carousel from 'react-bootstrap/Carousel';

const slides = [
    {
        id: 1,
        backgroundURI: '/slide3w.jpg',
        backgroundURIALT: '/slide3v.jpg',
        title: "",
        text: "",
        button: null
    },
    {
        id: 2,
        backgroundURI: '/slide4w.jpg',
        backgroundURIALT: '/slide4v.jpg',
        title: "",
        text: "",
        button: null
    },
    {
        id: 3,
        backgroundURI: '/slide1w.jpg',
        backgroundURIALT: '/slide1v.jpg',
        title: "",
        text: "",
        button: null
    },
    {
        id: 4,
        backgroundURI: '/slide2w.jpg',
        backgroundURIALT: '/slide2v.jpg',
        title: "",
        text: "",
        button: null
    },
    {
        id: 5,
        backgroundURI: '/slide5w.jpg',
        backgroundURIALT: '/slide5v.jpg',
        title: "",
        text: "",
        button: null
    }
]
const GalleryCmponent = (params) => {
    const createItems = (items) => {
        return items.map(element => {
            return <Carousel.Item>
                <picture>
                    <source media='(max-width: 768px)' srcSet={element.backgroundURIALT} />
                    <source media='(min-width: 768px)' srcSet={element.backgroundURI} />
                    <img src={element.backgroundURI} className="w-100" alt='idk' />
                </picture>
            
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        });
    }
    return (
        <div className="galleryComponent">
            <p className="gallerytitle mb-5">GALERÍA</p>
            <Carousel>
                {createItems(slides)}
            </Carousel>
        </div>
      
    )
    // <div className="galleryComponent">
    //     <p className="gallerytitle mt-5 mb-5">GALERIA</p>
    //     <p className="gallerysubtitle mb-5">Conocé el estudio</p>
    //     <CarrouselHeader data={{slides}} />
    //     {/* <CarouselExample /> */}
    // </div>
}

export default GalleryCmponent