import React from "react";
import SButton from "../../UIElements/Button/button";
import DropdownItem from "../../UIElements/Dropdown/DropdownItem";
import './EquipamientoComponent.css'
const data = {
    equipamiento: [
        {
            imagen: "/grabacion.png",
            title: "Grabación",
            categorias: [
                {
                    title: "Interfaces de Audio/Conversores",
                    show: false,
                    items: [
                        {
                            text: "Universal Audio Apollo"
                        },
                        {
                            text: "Apogee Ensemble"
                        },
                        {
                            text: "SSL Alphalink MX 1604/0416"
                        }
                    ]
                },
                {
                    title: "Software/DAW",
                    show: false,
                    items: [
                        {
                            text: "Pro Tools"
                        },
                        {
                            text: "Logic Pro"
                        },
                    ]
                },
                {
                    title: "Preamplificadores",
                    show: true,
                    items: [
                        {
                            text: "SA 1073 MPA"
                        },
                        {
                            text: "SSL ALPHA VHD x4"
                        },
                        {
                            text: "SSL 9k Clon"
                        },
                        {
                            text: "SA-1073T"
                        }
                    ]
                },
                {
                    title: "Microfonos",
                    show: true,
                    items: [
                        {
                            text: "Stam Audio ELA-M 251 apareados"
                        },
                        {
                            text: "Stam Audio 87T"
                        },
                        {
                            text: "Stam Audio 47 mk3"
                        },
                        {
                            text: "Universal Audio Sphere LX"
                        },
                        {
                            text: "Warm Audio 47jr"
                        },
                        {
                            text: "Microfono custom FET-12"
                        },
                        {
                            text: "Microfono Schoeps Clean M7"
                        },
                        {
                            text: "Josephson C42"
                        },
                        {
                            text: "Charter Oak m990 apareados"
                        },
                        {
                            text: "Sennheiser e602"
                        },
                        {
                            text: "Shure SM57"
                        },
                        {
                            text: "Shure SM7B"
                        },
                        {
                            text: "Electro Voice ND-46 x3"
                        },
                    ]
                },
                {
                    title: "Herramientas",
                    show: false,
                    items: [
                        {
                            text: "Rupert Neve DI"
                        },
                        {
                            text: "Radial Reamp box"
                        },
                        {
                            text: "Cableado Belden/Mogami"
                        },
                        {
                            text: "Patchera Redco 96 puntos."
                        },
                    ]
                },
            ]
        },
        {
            imagen: '/mezcla.png',
            title: 'Mezcla / Mastering Digital',
            categorias: [
                {
                    title: 'Monitoreo',
                    show: false,
                    items: [
                        {
                            text: 'Yamaha H80'
                        },
                        {
                            text: 'Amphion One15'
                        }
                    ]
                },
                {
                    title: "Software/DAW",
                    show: false,
                    items: [
                        {
                            text: "Pro Tools"
                        },
                        {
                            text: "Logic Pro"
                        },
                    ]
                },
                {
                    title: 'Paquete de Plug ins',
                    show: false,
                    items: [
                        {
                            text: 'Softube Volume One/Weiss/Otros'
                        },
                        {
                            text: 'Plug in Alliance full bundle'
                        },
                        {
                            text: 'Acustica Audio varios'
                        },
                        {
                            text: 'Black Rooster Audio'
                        },
                        {
                            text: 'Eventide Anthology'
                        },
                        {
                            text: 'Universal Audio La2a/3a/1176 packs y otros'
                        },
                        {
                            text: 'LiquidSonic'
                        },
                        {
                            text: 'Soundtoys All Plug in bundle'
                        },
                        {
                            text: 'Kush Clariphonic/UBK'
                        },
                        {
                            text: 'Celemny Melodyne'
                        },
                        {
                            text: 'Antares Autotune'
                        },
                        {
                            text: 'Muchos más!'
                        }
                    ]
                }
            ]
        }
    ]
}
const EquipamientoComponent = (config) => {
    const {action} = config
    const processEquipamiento = (equipamiento) => {
        return equipamiento.map(seccion => {
            return <div className="container" key={seccion.title}>
                    <img src={seccion.imagen} className="equipamientoImage" />
                    <p className="equipamientoSubTitle"> {seccion.title}</p>
                    {seccion.categorias.map(categoria => {
                        return <DropdownItem title={categoria.title} items={categoria.items} show={categoria.show} />
                    })}
                </div>
        })
    }
    return <div className="equipamientoBackground">
        <div className="container">
            <div className="equipamiento-box">
            <p className="equipamientoTitle">EQUIPAMIENTO</p>
            {processEquipamiento(data.equipamiento)}
        <div className="mt-5 pt-5 equipamiento-button-container">
        <SButton text="SOLICITAR ASESORAMIENTO" onClick={action} />
            </div>
        
        </div>
        </div>
        
        
    </div>
}

export default EquipamientoComponent