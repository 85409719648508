import React from "react";
import './button.css'
// import { Button } from "bootstrap";
const OrangeButton = (config) => {
    var {text, action, selected} = config
    // console.log(selected);
    return <div className="">
        {/* <a src="./" className="sbuttonText">HACER UNA RESERVA</a> */}
        <button className={selected ? "OButton" : "OButtonInactive"} onClick={action}>{text}</button>
    </div>
}

export default OrangeButton