import React, { useState } from "react";
import SButton from "../Button/button";
import OrangeButton from "../OrangeButton/button";
import './PackChooser.css'
const data = [
    {
        text: "Grabá tu música",
        fromIndexSelection: 0
    },
    {
        text: "Tus temas listos para publicar",
        fromIndexSelection: 0
    },
    {
        text: "Producción de sonido",
        fromIndexSelection: 1 
    },
    {
        text: "Mastering",
        fromIndexSelection: 1 
    },
    {
        text: "Arreglos únicos",
        fromIndexSelection: 2 
    },
    {
        text: "Buscamos tu sonido",
        fromIndexSelection: 2 
    },
    {
        text: "Stems de tu canción listos para Atmos",
        fromIndexSelection: 2 
    },
]
const PackChooser = (config) => {
    
    const [index, setIndex] = useState(1);
    const {onIndexSelection, action} = config
    const selectButton = (i) => {
        setIndex(i)
    }
    const generateStarLine = (text, isSelected) => {
        return <div className={isSelected ? null : "packSelection"}>
            <p className={isSelected ? "packtext" : "packtextDeselect"}><span class={`glyphicon glyphicon-star ${ isSelected ? 'iconSelected' : 'icondeselected'}`} aria-hidden="true"></span> {text}</p>
        </div>
    }
    const generateFeatures = (features) => {
        return features.map(feature => generateStarLine(feature.text, feature.fromIndexSelection <= index))
    }
    return <div className="packchooserbox">
        <div className="packchooserbuttonbox">
            <div className="row">
                <div className="col-md-4">
                    <OrangeButton text="Básico" selected={index == 0} action={() => selectButton(0)} />
                </div>
                <div className="col-md-4">
                    <OrangeButton text="Standard" selected={index == 1} action={() => selectButton(1)} />
                </div>
                <div className="col-md-4">
                    <OrangeButton text="Premium" selected={index == 2} action={() => selectButton(2)} />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-3"></div>
            <div className="col-xs-6 row">
                {generateFeatures(data)}
                <div className="buttonHolder">
                    <SButton text="Contrata el servicio" onClick={action} />
                </div>
            </div>
            <div className="col-xs-3"></div>
        </div>
    </div>
}

export default PackChooser