import React from "react";
import SButton from "../../UIElements/Button/button";
// import Carousel from "bootstrap/js/src/carousel";
import './CarrouselHeader.css'
import _ from 'lodash'
import 'bootstrap/dist/css/bootstrap.min.css';


const CarrouselHeader = (params) => {
    const {data, action} = params

    const createCarousel = (slides) => {
        
        return slides.map(slide => {
            // console.log(!_.isEmpty(slide.button));
            // console.log(slide.id == data.slides[0].id);
            return <div className={slide.id == data.slides[0].id ? "carousel-item active" : "carousel-item" } key={slide.id}>
            {/* <img id="img1" className="w-100" src={slide.backgroundURI} alt="First slide" />
            <img id="img2" className="w-100" src={slide.backgroundURIALT} alt="First slide" /> */}
             <picture>
                <source media='(max-width: 768px)' srcSet={slide.backgroundURIALT} />
                <source media='(min-width: 768px)' srcSet={slide.backgroundURI} />
                <img src={slide.backgroundURI} className="w-100" alt='idk' />
            </picture>
            <div className="carousel-caption">
                {/* <p className="studioLabelText">{slide.text}</p> */}
                {/* <img src="/logo2.png" height="350" width="360" /> */}
                <picture>
                <source media='(max-width: 768px)' srcSet="/logo2-v.png" />
                <source media='(min-width: 768px)' srcSet="/logo2-h.png" />
                <img src={slide.backgroundURI} className="" alt='idk' />
            </picture>
                {/* <p className="studioPrimaryText mb-5">{slide.title}</p> */}
                <div className="mb-5 pb-5">
                    {
                        !_.isEmpty(slide.button) ? <SButton text={slide.button} onClick={action} /> : <div></div>
                    }
                </div>
            </div>
        </div>
        })
    }
    return (
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                {createCarousel(data.slides)}
            </div>
        </div>
    )
}

export default CarrouselHeader