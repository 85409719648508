import React from "react";
import './CardText.css'
const CardText = (props) => {
    const {title, description, columns} = props
    return (
        <div className="col-sm cardview">
            <h5 className="studiotitle">{title}</h5>
            <p className="studiodescription">{description}</p>
        </div>
    )
}

export default CardText