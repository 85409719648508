import React from "react";
import './whatsapp.css'
const WhatsAppButton = () => {
    return <div className="floatingWhatsapp">
        <a href="https://api.whatsapp.com/send/?phone=5401163705523&text&type=phone_number&app_absent=0" target="_blank">
            <img src="/whatsapp.png" />
        </a>
    </div>
}

export default WhatsAppButton