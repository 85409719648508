import React, { useState } from 'react'
import './Navbar.css'

const NavigationBar = (props) => {
    const {items} = props
    const [showMenu, setShowMenu] = useState(false)
    let buildItems = () => {
        return items.map(item => {
            return <div className='col'><a href={item.url} className='navbar-link'><p>{item.title.toUpperCase()}</p></a></div>
        })
    }
    let displayMenu = () => {
        if (showMenu) {
            return <div className='navbar-popup-body'>
                {/* <a className="navbar-brand" href="/">
                    <img className="mt-3 ml-5" src="/logo2.png" width="90" height="71" alt="" />
                </a> */}
                <button className='navbar-button'><img src="/menu-close.png" alt="my image" onClick={() => setShowMenu(false)} /></button>
                <div className='navbar-container row'>
                {/* <div className='col-sm-2'></div> */}
                    {buildItems()}
                    {/* <div className='col-sm-2'></div> */}
                </div>
            </div>
        } else {
            return <div className="navbarBody">
            <div className='navbar-container'>
                {/* <a className="navbar-brand" href="/">
                    <img className="mt-3 ml-5" src="/logo2.png" width="90" height="71" alt="" />
                </a> */}
                </div>
            <button className='navbar-button'><img src="/menu-open.png" alt="my image" onClick={() => setShowMenu(true)} /></button>
        </div>    
        }
    }
    return displayMenu()
}

export default NavigationBar