import React from "react";
import "./CardIconText.css"
const CardIconText = (data) => {
    const {image, title, description} = data
    // console.log(description)
    return (
        <div className="col-sm">
            <img src={image} />
            <p className="cardicontexttitle pt-3">{title}</p>
            <p className="cardicontextbody">{description}</p>
        </div>
    )
}

export default CardIconText