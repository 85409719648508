import React from "react";
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import './ReviewsComponent.css'

const reviewData = [
    {
        author: "Kofla Delmonte @kdelmontek",
        song: "Gato del monte",
        description: "Estoy muy conforme después de laburar en el Estudio 811, son detallistas con el trabajo, siempre están actualizándose, buscando cosas nuevas y se adaptan según lo que surge. Salieron cosas copadas y espero que salgan más! ",
        job: "Cantante",
        url: "https://www.instagram.com/kdelmontek/",
        image: "delmonte.jpg"
    },
    {
        author: "Ricardo Paz @ricky.enrry",
        song: "Vente conmigo",
        description: 'La verdad llegué sin saber nada de lo que es una producción, llegué solo con mis ganas y mis letras. Ahora gracias a ustedes mis expectativas a la música no tiene límites. "Vente conmigo" será un himno en la música con nuestro esfuerzo',
        job: "Cantante",
        url: "https://www.instagram.com/ricky.enrry/",
        image: "ricky.jpg"
    },
    {
        author: "Lemon Lucc @luuc.ss",
        song: "Dot life",
        description: "Laburar con Estudio 811 fue una experiencia única. Tienen mucha creatividad y saben lo que hacen. Muy profesional todo",
        job: "Cantante",
        url: "https://www.instagram.com/luuc.ss/",
        image: "lucc.jpg"
    }
]
const ReviewComponent = () => {
    const data = reviewData
    const generateSlides = () => {
        return data.map( review => {
            return <SwiperSlide><Item slide={review}></Item></SwiperSlide>
        }
        )
    }
    return <div className="reviews-body">
        <p className="reviews-title">Lo que piensan nuestros clientes</p>
        <Swiper
            effect={'coverflow'}
            spaceBetween={30}
            loop={false}
            centeredSlides={true}

            pagination={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
            modules={[Pagination, EffectCoverflow]}
            className="mySwiper"
        >
            {generateSlides()}
        </Swiper>
    </div>
}

const Item = (props) => {
    const { text, slide } = props
    return <div className="content review-body">
        <div className="review-subbody">
        <p className="review-coma">“</p>
        <p className="review-description">{slide.description}</p>
        <img className="" src={`./resenas/${slide.image}`} />
        <a href={slide.url} className="review-author"><p>{slide.author}</p></a>
        <p className="review-song">{slide.song}</p>
        <p className="review-author">{slide.job}</p>
        </div>
        
        </div>
}
export default ReviewComponent