import React from "react";
import "./TextAreaWhite.css"

const data = {
    text: "El equipamiento de Estudio 811 y la sala brindan una grabación de calidad para la mayoría de los proyectos que puedas necesitar, sean baterías acústicas, guitarras, bajos, voces, algunos sintetizadores e instrumentos de orquesta o cámara."
}
const TextAreaWhite = () => {
    return <div className="textAreaBackGround">
        <div className="textArea container">
            <div className="row">
                <div className="col-sm-2"></div>
                <p className="textAreaText col-sm-8">{data.text}</p>
                <div className="col-sm-2"></div>
            </div>
            
        </div>
    </div>
}

export default TextAreaWhite