import BasicRequest from './BasicRequest'
import {v4} from 'uuid'

const generateid = () => {
    return 'user-' + v4()
}
const getId = () => {
    let id = localStorage.getItem('unregistered-id')
    if (id) {
        return id
    } else {
        const newId = generateid()
        localStorage.setItem('unregistered-id', newId)
        return newId
    }
}

const host = 'http://143.244.150.175:4050/api'
const Contact = async (body) => {
    const response = await BasicRequest.postAction(`${host}/contacto`, body)
    return response
}
const Suscribe = async (email) => {
    const uuid = getId()
    const body = {email, uuid}
    console.log(body)
    const response = await BasicRequest.postAction(`${host}/suscriptor`, body)
    console.log(response)
    return response
}
const ServerAPI = {
    Contact,
    Suscribe
}
export default ServerAPI
