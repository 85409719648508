import React from "react";
import { useNavigate } from "react-router-dom";
import CarrouselHeader from "../Component/CarrouselHeader/CarrouselHeader";
import EquipamientoComponent from "../Component/Equipamiento/EquipamientoComponent";
import Footer from "../Component/Footer/FooterComponent";
import GalleryCmponent from "../Component/Gallery/GalleryComponent";
import Logos from "../Component/Logos/LogosComponent";
import MediaComponent from "../Component/MediaComponent/MediaComponent";
import TextAreaWhite from "../Component/TextAreaWhite/TextAreaWhite";
const carouselData = {
    slides: [
        {
            id: 123,
            backgroundURI: '/studio-img-h.png',
            backgroundURIALT: '/studio-img-v.png',
            title: "EL ESTUDIO",
            text: "",
            button: null
        }
    ]

}
const StudioScreen = (config) => {
    const {top, contact} = config
    const navigate = useNavigate()
    const navigateToHome = () => {
        navigate('/')
        top.current.scrollIntoView()
    }
    const navigateToContact = () => {
        navigate('/#contact-form')
        let action = () => contact.current.scrollIntoView()
        setTimeout(action, 100)
    }
    return (
        <div>
            <CarrouselHeader data={carouselData} />
            <TextAreaWhite />
            <Logos />
            <EquipamientoComponent action={navigateToContact} />
            {/* <MediaComponent /> */}
            <GalleryCmponent />
            {/* <Footer /> */}
        </div>
    )
}

export default StudioScreen