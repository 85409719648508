import React from "react";
import "./Integrante.css"


const Integrante = (integrante) => {
    const {nombre, imagen, titulo, texto, redes} = integrante.integrante
    // console.log(integrante);
    const isFirst = !integrante.isFirst
    // console.log(isFirst)
    const buildIcons = (element) => {
        const redes = element.map(red => {
            let iconImageURL = "" 
            switch (red.red) {
                case "Facebook":
                    iconImageURL = "/facebook.png"
                    break
                case "SitioWeb":
                    iconImageURL = "/web.png"
                    break
                case "Instagram":
                    iconImageURL = "/instagram.png"
                    break
                case "Linkedin":
                    iconImageURL = "/linkedin.png"
                    break
                case "Tiktok":
                    iconImageURL = "/tiktok.png"
                    break
            }
            return (<a href={red.url} target="_blank"><img className="logoIcono" src={iconImageURL} /></a>)
        })
        return <div className="webiconcontainer"> {redes}</div>
    }
    // const buildContent = (isFirst) => {

    // }
    const buildLeft = () => {
        // console.log("integrante is false")
        return <div className="row">
                <div className="col-sm-5 pr-0 integranteBoxImagen">
                    <img className="logoImagen" src={imagen} width="170px" height="170px"></img>
                </div>
                <div className="col-sm-7 pt-4">
                    <p className="titulo pt-1">{nombre}</p>
                    <p className="subtitulo compactpadding">{titulo}</p>
                    <p className="descripcion compactpadding pt-2">{texto}</p>
                    {buildIcons(redes)}
                </div>
        </div>
    }
    const buildRight = () => {
        console.log("integrante is right")
        return <div className="row">
            <div className="col-sm-7 pt-4">
                    <p className="titulo pt-1">{nombre}</p>
                    <p className="subtitulo compactpadding">{titulo}</p>
                    <p className="descripcion compactpadding pt-2">{texto}</p>
                    {buildIcons(redes)}
                </div>
                <div className="col-sm-5 pr-0">
                    <img className="logoImagen" src={imagen} width="170px" height="170px"></img>
                </div>
                
        </div>
    }
    return (
        <div className="cuerpo">
            {/* <div className="row"> */}
                {/* <div className="col-sm-1"></div> */}
                {/* <div>
                    <img className="logoImagen" src={imagen} width="170px" height="170px"></img>
                </div> */}
                
                
                {/* <div className="pt-4 inline">
                    <p className="titulo pt-1">{nombre}</p>
                    <p className="subtitulo compactpadding">{titulo}</p>
                    <p className="descripcion compactpadding pt-2">{texto}</p>
                    {buildIcons(redes)}
                </div> */}
                {isFirst ? buildRight() : buildLeft()}
                {/* <div className="col-sm-5 pr-0">
                    <img className="logoImagen" src={imagen} width="170px" height="170px"></img>
                </div>
                <div className="col-sm-7 pt-4">
                    <p className="titulo pt-1">{nombre}</p>
                    <p className="subtitulo compactpadding">{titulo}</p>
                    <p className="descripcion compactpadding pt-2">{texto}</p>
                    {buildIcons(redes)}
                </div> */}
                
            {/* </div> */}
        </div>
    )
}

export default Integrante