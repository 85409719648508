import Joi from 'joi'

const nameSchema = Joi.object({
    text: Joi.string().min(2).max(30).required()
})
const emailSchema = Joi.object({
    text: Joi.string().min(2).max(30).required().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net', 'ar'] } })
})
const phoneSchema = Joi.object({
    text: Joi.string().min(8).max(16).pattern(/^[0-9]+$/).required()
})
const detailsSchema = Joi.object({
    text: Joi.string().max(300)
})
const optionSchema = Joi.object({
    text: Joi.string().min(2).max(30).required()
})
const schema = Joi.object({

})
const validateContact = (contact) => {
    
}
const validateName = async (name) => {
    await nameSchema.validateAsync({text: name})
}
const validateEmail = async (name) => {
    await emailSchema.validateAsync({text: name})
}
const validatePhone = async (name) => {
    await phoneSchema.validateAsync({text: name})
}
const validateOptions = async (name) => {
    await optionSchema.validateAsync({text: name})
}
const validateDetail = async (name) => {
    await detailsSchema.validateAsync({text: name})
}
const Validator = {
    validateName,
    validateEmail,
    validatePhone,
    validateOptions,
    validateDetail
}
export default Validator
