import React from "react";
import './button.css'
// import { Button } from "bootstrap";
const SButton = (config) => {
    var {text, onClick, disabled = false} = config
    // console.log(action)
    return <div className="">
        {/* <a src="./" className="sbuttonText">HACER UNA RESERVA</a> */}
        <button className={disabled ? "SButtonDisabled" : "SButton"} onClick={onClick} disabled={disabled}>{text}</button>
    </div>
}

export default SButton