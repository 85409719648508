import React, { useEffect, useState } from "react";
import CardText from "../../UIElements/CardText/CardText";
import Integrante from "./Integrante/Integrante";

import "./About.css"
import SButton from "../../UIElements/Button/button";
const data = {
    title: "ACERCA DE STUDIO 811",
    cards: [
        {
            title: "Creamos experiencias únicas",
            description: "Estudio 811 es un estudio de grabación, mezcla y mastering que busca ayudar a músicos a encontrar su propio sonido de forma cómoda y profesional con una experiencia única."
        },
        {
            title: "Amplia trayectoria",
            description: "Desde el 2010 trabajamos con músicos a nivel nacional e internacional, de diferentes géneros musicales. \nA principio de ese año comenzamos a ser una sala de ensayo para luego transformarnos en un Estudio de grabación de alta gama."
        },
        {
            title: "Tecnología de punta",
            description: "Comenzamos con equipo hogareño de grabación y fuimos creciendo hasta contar con nuestro equipamiento actual, de la calidad de cualquier estudio profesional."
        },
    ],
    boton: {
        texto: "Conocer el estudio",
        accion: ""
    },
    nosotros: {
        title: "NOSOTROS",
        integrantes: [
            {
                nombre: "Santiago Linietsky",
                titulo: "Ingeniero en Sonido",
                imagen: "./Santi6.png",
                texto: "Productor musical para medios audiovisuales, ingeniero de grabación, mezcla y mastering. Más de 10 años de experiencia en el rubro, realizó capacitaciones con ingenieros cómo Chris Lord Alge y Vance Powell.",
                isFirst: false,
                redes: [
                    {
                        red: "Facebook",
                        url: "http://google.com"
                    },
                    {
                        red: "Linkedin",
                        url: "http://google.com"
                    },
                    {
                        red: "SitioWeb",
                        url: "http://google.com"
                    },
                ]
            },
            {
                nombre: "Berni Wav",
                titulo: "Productor",
                imagen: "./Berni2.png",
                isFirst: true,
                texto: "Licenciado en Composición musical y Técnico universitario en Producción. Con más de 10 años de experiencia como productor y músico sesionista multinstrumentista.",
                redes: [
                    {
                        red: "Tiktok",
                        url: "https://www.tiktok.com/@berni.wav"
                    },
                    {
                        red: "Instagram",
                        url: "https://www.instagram.com/berni.wav/"
                    },
                    {
                        red: "SitioWeb",
                        url: "https://berniwav.com/"
                    },
                ]
            },
        ]
    }
}

const AboutComponent = (config) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const {action} = config
    const cards = (cards) => {
        // return data.cards.map({ card => <CardText title={card.title} description={card.description}>})
        return cards.map( card => {
            return <div className="col-sm" key={card.title}><CardText title={card.title} description={card.description} /></div>})
    }
    const integrantes = (integrantes) => {
        return integrantes.map( integrante => {
            return (
                <div className="col-sm-6 mt-5 mb-5">
                    <Integrante integrante={integrante} isFirst={width > 768 ? integrante.isFirst : true} />
                </div>
            )
        }) 
    }
    
    return (
        <div className="about-container about-container-separator">
            <img className="about-top-right-frame" src="/frame-us-tr.png" />
            
            <div className="containeraboutbodyoutter">
            <div className="container containeraboutbody">
                <p className="title mt-5 pt-5 mb-5 pb-5">{data.title}</p>
                <div className="row">
                    {cards(data.cards)}
                </div>    
                <img className="about-bottom-left-frame2" src="/frame-us-bl.png" />
            </div>
            
            <div className="about-container">
                
                <img className="about-top-right-frame" src="/frame-us-tr.png" />
                <div className="row subcontaineraboutbody about-container">
                    <p className="title">{data.nosotros.title}</p>
                    {integrantes(data.nosotros.integrantes)}
                </div>
                <SButton text={data.boton.texto} onClick={action} ></SButton>
                </div>
                <img className="about-bottom-left-frame" src="/frame-us-bl.png" />
            </div>
        </div>
        
    )
}

export default AboutComponent