import React, { useState } from "react";
import PackChooser from "../../UIElements/PackChooser/PackChooser";
import './PackComponent.css'

const data = {
    title: 'Elegí tu pack',
    description: 'Encontrá el pack que se adapta a tus necesidades. Contamos con variedad de opciones y posibilidades.',
    description2: 'Todos los packs incluyen acceso al estudio y uso de equipamiento profesional.'
}
const PackComponent = (config) => {
    const {action} = config
    const [index, setIndex] = useState(1)
    return (
        <div className="pack-container">
            <img className="pack-top-right-frame" src="/frame-pack.png" />
            <img className="pack-bottom-left-frame" src="/frame-pack-bl.png" />
            <div className="container">
            
            <div className="row">
                <div className="col-sm-5 pt-5 pb-5">
                    <div className="packcomponenttextbox">
                        <p className="packcomponenttitle pt-5">{data.title}</p>
                        <p className="packcomponentdescription pt-4">{data.description}</p>
                        <p className="packcomponentdescription pt-4">{data.description2}</p>
                    </div>
                </div>
                <div className="col-sm-7 mt-5">
                    <PackChooser onIndexSelection={(i) => setIndex(i)} action={action} />
                </div>
            </div>
        </div>
        </div>
    )
}

export default PackComponent